import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadReport } from '../api/reports';
import { createNewTabBlob } from '../utils/helpers';
export default function useReportDownload() {
  const { t } = useTranslation('common');
  const handleReportClick = useCallback(async (id) => {
    try {
      let myWindow = null;
      const winUrl = createNewTabBlob(t('common:downloadingFile'));
      myWindow = window.open(winUrl, '_blank');
      const res = await downloadReport(id);

      myWindow.location.href = res.link;

      return res;
    } catch (e) {
      console.log('download report error', e);
    }
  }, []);

  return [handleReportClick];
}
