import { useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button } from '@material-tailwind/react';
import Select from 'react-select';
import { useAtom } from 'jotai';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useForm, Controller } from 'react-hook-form';

import { companiesDataAtom } from '../atoms/companies';
import { notificationAtom, isLoadingAtom } from '../atoms/app';
import { createUser } from '../api/users';
import Notification from '../components/Notification';
import Loader from '../components/Loader';
import Header from '../components/Header/Header';
import FormError from '../components/FormError/FormError';
import { generateSelectOptions } from '../utils/helpers';

function CreateUser() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors }
  } = useForm();
  const { t } = useTranslation(['common', 'buttons']);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [companiesData] = useAtom(companiesDataAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [showPassword, setShowPassword] = useState(false);

  const options = useMemo(() => generateSelectOptions(companiesData), [companiesData]);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const companies = data.companies.map((c) => c.value);
      let formData = {
        username: data.username,
        password: data.password,
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        parent_company: data.parentCompany.value,
        companies
      };
      await createUser(formData);
      setNotification({ isOpen: true, type: 'success', message: t('common:userCreated') });
      reset();
      setIsLoading(false);
    } catch (e) {
      console.log('Error', e);
      setIsLoading(false);
      setNotification({
        isOpen: true,
        type: 'failure',
        message: e.message || t('common:somethingWrong')
      });
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const formPassword = useRef({});
  formPassword.current = watch('password', '');

  return (
    <>
      <Header></Header>
      {<Notification isTempopary={true} />}
      <div className="container h-full px-6 pb-12 pt-20">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            {isLoading && <Loader />}
            {!isLoading && (
              <form>
                <div className="relative mb-6">
                  <Input
                    required
                    color="blue"
                    label={t('common:username')}
                    {...register('username', { required: true })}
                  />
                  <FormError text={t('common:fieldRequired')} isVisible={errors?.username} />
                </div>
                <div className="relative mb-6">
                  <Input
                    required
                    color="blue"
                    label="Email"
                    {...register('email', {
                      required: { value: true, message: t('common:fieldRequired') },
                      pattern: { value: /^\S+@\S+$/i, message: t('common:emailInvalid') }
                    })}
                  />
                  <FormError text={errors?.email?.message} isVisible={errors?.email} />
                </div>
                <div className="relative mb-6">
                  <Input
                    required
                    color="blue"
                    label="Firstname"
                    {...register('firstname', { required: true })}
                  />
                  <FormError
                    text={t('common:fieldRequired')}
                    isVisible={errors?.firstname?.type === 'required'}
                  />
                </div>
                <div className="relative mb-6">
                  <Input
                    required
                    color="blue"
                    label="Lastname"
                    {...register('lastname', { required: true })}
                  />
                  <FormError
                    text={t('common:fieldRequired')}
                    isVisible={errors?.lastname?.type === 'required'}
                  />
                </div>
                <div className="relative mb-6">
                  <Input
                    required
                    color="blue"
                    icon={
                      showPassword ? (
                        <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                      ) : (
                        <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                      )
                    }
                    type={showPassword ? 'text' : 'password'}
                    label={t('common:password')}
                    {...register('password', {
                      required: { value: true, message: t('common:fieldRequired') },
                      minLength: {
                        value: 8,
                        message: t('common:passwordLength')
                      }
                    })}
                  />
                  <FormError text={errors?.password?.message} isVisible={errors?.password} />
                </div>
                <div className="relative mb-6">
                  <Input
                    required
                    color="blue"
                    icon={
                      showPassword ? (
                        <FiEyeOff className="cursor-pointer" onClick={toggleShowPassword} />
                      ) : (
                        <FiEye className="cursor-pointer" onClick={toggleShowPassword} />
                      )
                    }
                    type={showPassword ? 'text' : 'password'}
                    label={t('common:repeatPassword')}
                    {...register('password_repeat', {
                      validate: (value) =>
                        value === formPassword.current || t('common:passwordMissmatch')
                    })}
                  />
                  <FormError
                    text={errors?.password_repeat?.message}
                    isVisible={errors?.password_repeat}
                  />
                </div>
                <div className="relative mb-6">
                  <Controller
                    name="parentCompany"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || null}
                        options={options}
                        placeholder={t('common:selectParent')}
                        isSearchable={false}
                        onChange={(value) => {
                          setValue('companies', [value], { shouldValidate: true });
                          return setValue('parentCompany', value, { shouldValidate: true });
                        }}
                        classNames={{
                          singleValue: () => 'w-56',
                          control: () => '[&&]:cursor-pointer font-medium',
                          option: () => '[&&]:cursor-pointer'
                        }}
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                  <FormError
                    text={t('common:fieldRequired')}
                    isVisible={errors?.parentCompany?.type === 'required'}
                  />
                </div>
                <div className="relative mb-6">
                  <Controller
                    name="companies"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || null}
                        isMulti
                        options={options}
                        placeholder={t('common:selectCompanies')}
                        isSearchable={false}
                        classNames={{
                          singleValue: () => 'w-56',
                          control: () => '[&&]:cursor-pointer font-medium',
                          option: () => '[&&]:cursor-pointer'
                        }}
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                  <FormError
                    text={t('common:fieldRequired')}
                    isVisible={errors?.companies?.type === 'required'}
                  />
                </div>
                <div className="relative mb-6">
                  <Button color="green" type="submit" onClick={handleSubmit(onSubmit)}>
                    {t('buttons:createUser')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateUser;
