import { useEffect, useState, useCallback } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

import { fetchDocumentation } from '../../api/docs';
import { isLoadingAtom } from '../../atoms/app';
import Loader from '../../components/Loader';
import { downloadPDF } from '../../utils/helpers';
import { pdfMaxWidth } from '../../helpers/constants';
import useDeviceDetect from '../../hooks/useDeviceDetect';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfDoc = () => {
  const { t } = useTranslation(['buttons']);
  const { app, type } = useParams();
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [link, setLink] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const isTouchDevice = useDeviceDetect();

  const handlePdfDownload = useCallback(() => {
    downloadPDF(link, `${app}_${type}`, isTouchDevice);
  }, [link, app, type, isTouchDevice]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    setLink(null);
    const fetchDoc = async () => {
      try {
        setIsLoading(true);
        setNumPages(null);
        setPageNumber(1);
        const res = await fetchDocumentation(app, type);

        setLink(res.b64string);
        setIsLoading(false);
      } catch (error) {
        console.log('Error', error);
        setIsLoading(false);
      }
    };

    fetchDoc();
  }, [app, type]);

  return (
    <>
      {isLoading && (
        <div className="w-[900px]">
          <Loader />
        </div>
      )}

      {link && (
        <div className="flex">
          <div className="w-[900px]">
            <Document
              loading={' '}
              file={`data:application/pdf;base64,${link}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => {
                console.log('error', error);
              }}
              // options={options}
            >
              <Page loading={' '} width={pdfMaxWidth} pageNumber={pageNumber} />
            </Document>
          </div>
          <div>
            <Typography className="mb-2">
              {`${t('common:page')} ${pageNumber || (numPages ? 1 : '--')} ${t('common:of')} ${
                numPages || '--'
              }`}
            </Typography>

            <Button
              className="mr-4 mb-2"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              color="light-blue"
              variant="gradient"
            >
              {t('buttons:previous')}
            </Button>
            <Button
              className="mr-4 mb-2"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              color="light-blue"
              variant="gradient"
            >
              {t('buttons:next')}
            </Button>
            <Button
              disabled={!link}
              className="mb-2"
              onClick={handlePdfDownload}
              color="light-blue"
              variant="gradient"
            >
              {t('buttons:download')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default PdfDoc;
