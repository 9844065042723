import { Typography } from '@material-tailwind/react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DocNavigation = () => {
  const { t } = useTranslation(['menu']);

  return (
    <div className="flex flex-col gap-y-4 w-[300px]">
      <div className="">
        <Typography variant="h6" color="blue-gray">
          {`${t('menu:verifyDevice')}:`}
        </Typography>
        <div className="flex flex-col gap-2 pl-2">
          <NavLink
            to="app/manual"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:userManual')}
          </NavLink>
          <NavLink
            to="app/agreement"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:userAgreement')}
          </NavLink>
          <NavLink
            to="app/notes"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:releaseNotes')}
          </NavLink>
          <NavLink
            to="app/quick"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:quickStartGuide')}
          </NavLink>
        </div>
      </div>
      <div className="">
        <Typography variant="h6" color="blue-gray">
          {`${t('menu:verifyUI')}:`}
        </Typography>
        <div className="flex flex-col gap-2 pl-2">
          <NavLink
            to="webui/manual"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:userManual')}
          </NavLink>
          <NavLink
            to="webui/agreement"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:userAgreement')}
          </NavLink>
          <NavLink
            to="webui/notes"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:releaseNotes')}
          </NavLink>
          <NavLink
            to="webui/quick"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-800 font-medium border-l-2 border-l-blue-800 pl-2'
                : 'text-blue-700 hover:text-blue-500'
            }
          >
            {t('menu:quickStartGuide')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default DocNavigation;
