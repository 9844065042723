import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import Header from '../../components/Header/Header';
import { companiesDataAtom, currentCompanyAtom } from '../../atoms/companies';
import useColumns from './useColumns';
import { videoTypeAtom, loadableVideosAtom } from '../../atoms/video';
import Filters from './Filters';

import '../../index.css';
import '../../styles/datePicker.css';

function VideoList() {
  const { height, width } = useWindowDimensions();
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const [companiesData, setCompaniesData] = useAtom(companiesDataAtom);
  const [videoType, setVideoType] = useAtom(videoTypeAtom);
  const [videos] = useAtom(loadableVideosAtom);

  const currentCompanyData = companiesData?.find((c) => c.id === selectedCompany);

  const navigate = useNavigate();

  const handleRowClick = (e, data) => {
    e.stopPropagation();
    navigate(`/video/${data}`, { replace: false });
    if (videoType === 'wo') {
      setVideoType('wt');
    }
  };

  const columns = useColumns(currentCompanyData?.type, handleRowClick);

  const gridStyle = {
    minHeight: height - 189,
    minWidth: width - 650
  };

  const isLoading = videos.state === 'loading';

  return (
    <>
      <Header></Header>
      <div className="flex border-t border-gray-200 dark:border-gray-600">
        <Filters companyType={currentCompanyData?.type} />
        <div className="flex-1 p-4">
          {/* {isLoading && <Loader />}
          {videos.state === 'hasData' && !isLoading && ( */}
          <ReactDataGrid
            idProperty="videosGrid"
            columns={columns}
            style={gridStyle}
            rowHeight={null}
            loading={isLoading}
            loadingText={' '}
            rowMinHeight={60}
            // emptyText=""
            dataSource={videos?.data ? videos?.data.videos : []}
            pagination
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default VideoList;
